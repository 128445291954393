<template>
  <v-sheet flat>
    <users-view
      ref="user_view"
      :isAdmin="isAdmin"
      :quoteUser="quoteUser"
      :isQuoteIncomplete="isQuoteIncomplete"
      @saveData="onSaveForm"
      @saveUserData="onSaveUserData"
    />
    <company-view
      ref="company_view"
      :isAdmin="isAdmin"
      :editMode="editMode"
      :quote="quote"
      :quoteUser="quoteUser"
      :quoteCompany="quoteCompany"
      :isQuoteIncomplete="isQuoteIncomplete"
      :billingAddress="billingAddress"
      :shippingAddress="shippingAddress"
      @saveUserData="onSaveUserData"
      @saveCompanyData="onSaveCompanyData"
      @downloadNDA="onDownloadNDA"
    />
    <v-btn x-large class="ml-10 my-5" @click="onSave" color="secondary" data-cy="rfq-user-button-continue">{{
      (!inputValid && (editMode || Object.keys(quote).length > 0) ? "Save and " : "") + "Continue"
    }}</v-btn>
  </v-sheet>
</template>
<script>
import { fileDownloader } from "@/helpers/basehelper.js";
import { mapActions, mapMutations } from "vuex";
export default {
  name: "UsersViewPage",
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
    quote: {
      type: Object,
      default: null,
    },
    quoteUser: {
      type: Object,
      default: null,
    },
    quoteCompany: {
      type: Object,
      default: null,
    },
    inputValid: {
      type: Boolean,
      default: false,
    },
    isQuoteIncomplete: {
      type: Boolean,
      default: true,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    billingAddress: {
      type: Object,
      default: null,
    },
    shippingAddress: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      addressIdToDelete: null,
    };
  },
  components: {
    UsersView: () => import("../components/UsersView.vue"),
    CompanyView: () => import("../components/CompanyView.vue"),
  },
  computed: {
    userCompany() {
      return this.$store.getters["ui/userCompany"];
    },
    uiShippingSameAsBilling() {
      return this.$store.getters["ui/shippingSameAsBilling"];
    },
    uiBillingAddress() {
      return this.$store.getters["ui/billingAddress"];
    },
    uiShippingAddress() {
      return this.$store.getters["ui/shippingAddress"];
    },
    isBillingAddressEdited() {
      return (
        this.uiBillingAddress.name != this.billingAddress.name ||
        this.uiBillingAddress.company_name !=
          this.billingAddress.company_name ||
        this.uiBillingAddress.email != this.billingAddress.email ||
        this.uiBillingAddress.phone != this.billingAddress.phone ||
        this.uiBillingAddress.street1 != this.billingAddress.street1 ||
        this.uiBillingAddress.street2 != this.billingAddress.street2 ||
        this.uiBillingAddress.city != this.billingAddress.city ||
        this.uiBillingAddress.state != this.billingAddress.state ||
        this.uiBillingAddress.country != this.billingAddress.country ||
        this.uiBillingAddress.zip != this.billingAddress.zip
      );
    },
  },
  methods: {
    fileDownloader,
    ...mapActions({
      saveUser: "auth/saveUser",
      saveCompany: "projects/updateCompanyById",
      saveQuote: "quotes/saveQuote",
      saveQuoteAddress: "quotes/saveQuoteAddress",
      deleteQuoteAddress: "quotes/deleteQuoteAddress",
      toggleLoading: "ui/loading",
    }),
    ...mapMutations({
      setUser: "projects/SET_USER",
    }),
    async onSave() {
      this.$refs["user_view"] &&
        this.$refs["user_view"].isUserDetailsValid();
      this.$refs["company_view"] &&
        this.$refs["company_view"].isBillingAddressValid();
      this.$refs["company_view"] &&
        this.$refs["company_view"].isShippingAddressValid();
      if (
        !this.isUserDetailsValid() ||
        !this.isBillingAddressValid() ||
        (!this.uiShippingSameAsBilling && !this.isShippingAddressValid())
      ) {
        this.$store.commit(
          "ui/SNACK_BAR",
          "Please fill up all required fields."
        );
        return;
      }

      if (this.inputValid || (!this.editMode && Object.keys(this.quote).length == 0)) {
        await this.onSaveUserData();
        this.onNextTab();
        return;
      }
      await this.onSaveUserData();
      await this.onSaveAddressData();
      this.onNextTab();
    },
    async onSaveUserData() {
      const actionPayload = {
        full_name: this.userCompany.full_name,
        company_request: this.userCompany.company_request,
      };
      if (!this.quoteCompany) {
        actionPayload.billing_address_request = this.userCompany.billing_address_request;
        actionPayload.shipping_same_as_billing_request = this.userCompany.shipping_same_as_billing_request;
        actionPayload.shipping_address_request = this.userCompany.shipping_address_request;
      }
      try {
        const response = await this.saveUser(actionPayload);
        this.setUser(response);
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    async onSaveAddressData() {
      const billingAddressPayload = {
        editMode: this.editMode || Object.keys(this.quote).length > 0,
        id:
          (this.editMode || Object.keys(this.quote).length > 0) &&
          this.billingAddress &&
          Object.keys(this.billingAddress).length > 1
            ? this.billingAddress.id
            : null,
        data: this.uiBillingAddress,
        isBilling: true,
      };
      try {
        this.toggleLoading("Saving Address Data");
        let resBillingAddress;
        console.log("isBillingEdited:", this.isBillingAddressEdited);
        if (this.isBillingAddressEdited) {
          resBillingAddress = await this.saveQuoteAddress(
            billingAddressPayload
          );
        } else {
          resBillingAddress = { id: this.billingAddress.id };
        }

        let resShippingAddress = null;
        if (this.uiShippingSameAsBilling) {
          if (
            (this.editMode || Object.keys(this.quote).length > 0) &&
            this.quote.billing_address != this.quote.shipping_address
          ) {
            this.addressIdToDelete = this.quote.shipping_address;
          }
        } else {
          const shippingAddressPayload = {
            editMode: false,
            id: null,
            data: this.uiShippingAddress,
            isBilling: false,
          };
          resShippingAddress = await this.saveQuoteAddress(
            shippingAddressPayload
          );
          console.log("Shipping Address Posted", resShippingAddress);
        }
        const quotePayload = {
          editMode: this.editMode || Object.keys(this.quote).length > 0,
          id:
            this.editMode || Object.keys(this.quote).length > 0
              ? this.quote.id
              : null,
          data: {
            billing_address: resBillingAddress.id,
            shipping_address: !this.uiShippingSameAsBilling
              ? resShippingAddress.id
              : resBillingAddress.id,
            shipping_same_as_billing: this.uiShippingSameAsBilling,
          },
        };
        await this.saveQuote(quotePayload);
        if (this.uiShippingSameAsBilling && this.addressIdToDelete) {
          await this.deleteQuoteAddress(this.addressIdToDelete);
          this.$store.commit("quotes/SET_SHIPPING_ADDRESS", null);
          this.addressIdToDelete = null;
        }
        this.toggleLoading();
      } catch (err) {
        this.toggleLoading();
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    async onSaveForm(payload) {
      try {
        const response = await this.saveUser(payload);
        this.setUser(response);
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    async onSaveCompanyData() {
      const actionPayload = {
        editMode: true,
        id: this.quoteCompany.id,
        data: {
          billing_address: this.userCompany.billing_address_request,
          shipping_same_as_billing: this.userCompany
            .shipping_same_as_billing_request,
          shipping_address: this.userCompany.shipping_address_request,
        },
      };
      try {
        await this.saveCompany(actionPayload);
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    onDownloadNDA(payload) {
      const actionPayload = {
        url: payload,
        file: payload.split("?")[0].split("/").pop(),
      };
      this.fileDownloader(actionPayload);
    },
    onNextTab() {
      this.$emit("nextTab");
    },
    isBillingAddressValid() {
      return (
        this.$refs["company_view"] &&
        this.$refs["company_view"].isBillingAddressValid()
      );
    },
    isShippingAddressValid() {
      return (
        this.$refs["company_view"] &&
        this.$refs["company_view"].isShippingAddressValid()
      );
    },
    isUserDetailsValid() {
      return (
        this.$refs["user_view"] &&
        this.$refs["user_view"].isUserDetailsValid()
      );
    },
  },
};
</script>
